<template>
  <div class="project-report">
    <div class="project-report-search">
      <a-form layout="inline">
        <a-form-item>
          <project-tree style="margin-bottom: 7px;" @select="handleTreeSelect"></project-tree>
        </a-form-item>
        <a-form-item>
          <a-select
            style="width: auto; min-width: 120px"
            placeholder="选择统计周期"
            allowClear
            v-model:value="searchModel.staTimePeriod"
            :options="periodOptions"
            @change="changePeriod"
          >
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-range-picker
            style="width: auto"
            :value="searchModel.times"
            :disabledDate="disabledDate"
            :show-time="showTime"
            :valueFormat="valueFormat"
            :picker="currentPicker"
            @change="onChangeTimes"
            @openChange="onOpenChange"
            @calendarChange="onCalendarChange"
          />
        </a-form-item>
        <a-form-item>
          <KpiPanelSelect
            placeholder="选择维度和统计指标"
            :options="kpiOptions"
            :loading="kpiOptionsLoading"
            v-model:value="searchModel.kpiCodes"
            @change="changeKpi"
          ></KpiPanelSelect>
        </a-form-item>
      </a-form>
      <div class="project-report-search-actions">
        <a-button
          type="primary"
          :loading="tableConfig.loading"
          @click="initTableData"
          >查询</a-button
        >
        <a-button :loading="exportLoading" @click="onExport">导出</a-button>
      </div>
    </div>
    <div class="project-report-table">
      <BasicTable
        :scroll="{ x: '100%' }"
        ref="tableRef"
        v-bind="tableConfig"
        @page-change="pageChange"
      ></BasicTable>
    </div>
  </div>
</template>
<script setup>
import { BasicTable } from "@/components/basic/table";
import { computed, onMounted, reactive, ref, toRaw } from "vue";
import { apiProjectReport } from "@/api/report.js";
import { downLoadFile } from "@/utils/excel";
import dayjs from "dayjs";
import _ from "lodash";
import { message, Form } from "ant-design-vue";
import projectTree from "@/components/business/projectTree/index.vue";

const useForm = Form.useForm;

// 搜索栏
const searchModel = reactive({
  bizProjectId: undefined,
  staTimePeriod: null,
  times: [],
  kpiCodes: [],
});
const { validate, validateInfos } = useForm(
  searchModel,
  reactive({
    bizProjectId: [
      {
        required: true,
      },
    ],
    staTimePeriod: [
      {
        required: true,
      },
    ],
    times: [
      {
        required: true,
      },
    ],
    kpiCodes: [
      {
        required: true,
      },
    ],
  })
);
// 项目列表
const handleTreeSelect = (arrayIds) => {
  searchModel.bizProjectId = arrayIds[0];
  searchModel.kpiCodes = [];
  kpiOptions.value = [];
  initKpiOptions();
};
// 统计周期
const periodOptions = [
  {
    label: "小时",
    value: 1,
    maxYear: 1,
    picker: "date",
    valueFormat: "YYYY-MM-DD HH:mm:ss",
  },
  {
    label: "天",
    value: 2,
    maxYear: 2,
    picker: "date",
    valueFormat: "YYYY-MM-DD",
  },
  {
    label: "月",
    value: 3,
    maxYear: null,
    picker: "month",
    valueFormat: "YYYY-MM",
  },
  {
    label: "年",
    value: 4,
    maxYear: null,
    picker: "year",
    valueFormat: "YYYY",
  },
];
let currentPicker = "date";
let valueFormat = null;
let maxYear = null;
const changePeriod = (value, option) => {
  searchModel.times = [];
  dates = [];
  maxYear = option?.maxYear ?? null;
  currentPicker = option?.picker ?? "date";
  valueFormat = option?.valueFormat ?? "YYYY-MM-DD";
  searchModel.kpiCodes = [];
  kpiOptions.value = [];
  initKpiOptions();
};
// 日期范围
let dates = [];
const disabledDate = (current) => {
  if (!dates || dates.length === 0 || !maxYear) {
    return false;
  }
  const tooLate = dates[0] && dayjs(current).diff(dates[0], "year") >= maxYear;
  const tooEarly = dates[1] && dayjs(dates[1]).diff(current, "year") >= maxYear;
  return tooEarly || tooLate;
};
const onCalendarChange = (val) => {
  dates = val;
};
const onOpenChange = (open) => {
  if (open) {
    dates = [];
  }
};
const onChangeTimes = (val) => {
  searchModel.times = val;
};
const showTime = computed(() => {
  if (searchModel.staTimePeriod === 1) {
    return {
      defaultValue: [
        dayjs("00:00:00", "HH:mm:ss"),
        dayjs("23:59:59", "HH:mm:ss"),
      ],
    };
  } else {
    return false;
  }
});
// 维度指标
const kpiOptions = ref([]);
const kpiOptionsLoading = ref(false);
const initKpiOptions = async () => {
  const { bizProjectId, staTimePeriod } = searchModel;
  if (!bizProjectId) return;
  if (!staTimePeriod) return;
  const params = {
    bizProjectId,
    timePeriod: staTimePeriod,
  };
  kpiOptionsLoading.value = true;
  try {
    const { result } = await apiProjectReport.kpiList(params);
    kpiOptions.value = result;
  } catch (error) {
    console.log(error);
  }
  kpiOptionsLoading.value = false;
};
let selectKpiOptions = [];
const changeKpi = (value, selectedOptions) => {
  selectKpiOptions = selectedOptions;
};

const getApiParams = async () => {
  return new Promise((resolve, reject) => {
    validate()
      .then(() => {
        const { bizProjectId, staTimePeriod, times, kpiCodes } =
          toRaw(searchModel);
        const params = {
          bizProjectId,
          staTimePeriod,
          begin: times[0] || null,
          end: times[1] || null,
          kpiCodes,
        };
        resolve(params);
      })
      .catch(() => {
        reject("输入项未完成");
        const { bizProjectId, staTimePeriod, times, kpiCodes } = validateInfos;
        if (bizProjectId.validateStatus === "error") {
          message.warning("请选择项目");
          return;
        }
        if (staTimePeriod.validateStatus === "error") {
          message.warning("请选择统计周期");
          return;
        }
        if (times.validateStatus === "error") {
          message.warning("请选择时间范围");
          return;
        }
        if (kpiCodes.validateStatus === "error") {
          message.warning("请选择维度指标");
          return;
        }
      });
  });
};
// 导出
const exportLoading = ref(false);
const onExport = async () => {
  exportLoading.value = true;
  try {
    const params = await getApiParams();
    const res = await apiProjectReport.export(params);
    const { filename, blob } = res;
    console.log(1212333, filename, blob);
    downLoadFile(blob, decodeURI(filename));
    message.success("导出成功");
  } catch (error) {
    console.log(error);
  }
  exportLoading.value = false;
};

// 表格
const COLUMNS = [
  {
    title: "项目名称",
    type: "text",
    key: "projectName",
    width: 200,
  },
  {
    title: "项目编码",
    type: "text",
    key: "projectCode",
    width: 150,
  },
  {
    title: "统计时间",
    type: "text",
    key: "staTime",
    width: 150,
  },
];
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 274px)",
  },
  rowKey: "projectCode",
  columns: COLUMNS,
});
const tableRef = ref();
const initTableData = async () => {
  tableConfig.loading = true;
  try {
    let params = await getApiParams();
    tableConfig.columns = [
      ..._.cloneDeep(COLUMNS),
      ...selectKpiOptions.map((item) => ({
        title: `${item.label}（${item.unit}）`,
        type: "text",
        width: 200,
        ellipsis: true,
        key: item.value,
      })),
    ];
    const { current, pageSize } = tableRef.value.paginationProps;
    params = {
      ...params,
      pageNo: current,
      pageSize,
    };
    const { result } = await apiProjectReport.page(params);
    const { records, total } = result;
    tableRef.value.dataSourceRef = records || [];
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    console.log(error);
  }
  tableConfig.loading = false;
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

onMounted(() => {
  tableRef.value.paginationProps.pageSize = 20;
});
</script>
<style lang="less" scoped>
.project-report {
  display: flex;
  flex-direction: column;
  min-width: 1050px;
  height: 100%;
  background: #fff;

  &-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;

    &-actions {
      display: flex;
      gap: 8px;
    }
  }

  &-table {
    flex: 1;
    padding: 0 20px;
    // ::v-deep .ant-table-thead > tr > th {
    //   white-space: nowrap;
    // }
    // ::v-deep .ant-table-row td {
    //   white-space: nowrap;
    // }
  }
}
</style>
<style lang="less">
.project-report-dropdown-style {
  width: auto !important;
  max-height: 400px;
  overflow: auto;
}
</style>
