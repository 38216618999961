import http from "../utils/http";

// 项目报表
export const apiProjectReport = {
  projectTree() {
    return http.request({
      url: "/cemp-energy/sta/getProjectsTree",
      method: "get",
    });
  },
  kpiList(params) {
    return http.request({
      url: "/cemp-energy/sta/getProjectKpi",
      method: "get",
      params,
    });
  },
  page(data) {
    return http.request({
      url: "/cemp-energy/sta/project/list/data",
      method: "post",
      data,
    });
  },
  export(data) {
    return http.request({
      url: "/cemp-energy/sta/project/list/data/export",
      method: "post",
      responseType: "blob",
      data,
    });
  },
};
